import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import { breakpoints, layout, colors, borders } from '../../theme'
import {
  Button,
  StyledButton,
  AddressInput,
  StyledAddressInput,
  Pulse,
} from '@monbanquet/crumble'
import SalesPictureTitle from '../../components/SalesPictureTitle'
import { EventContext } from '../../components/context/EventContext'
import { AuthenticationContext } from '../../components/context/AuthenticationContext'
import { navigate } from '../../components/Link'
import { useRedirect } from '../../hooks/useRedirect'
import { useNoValidate } from '../../hooks/useNoValidate'
import * as yup from 'yup'
import { useFormalWithProps } from '../../hooks/useFormalWithProps'
import { useWithDelay } from '../../hooks/useWithDelay'
import { httpFetch } from '../../util/httpFetch'
import DemandeLayout from '../../components/DemandeLayout'
import { computeNextPageWithBaseUri } from '../../util/compute-next-page'

const schema = yup.object().shape({
  eventAddress: yup.string().required('Indiquez une adresse'),
})

const AddressPage = ({ location: { state } }) => {
  const redirect = useRedirect(!state || !state.next, '/demande/format')

  const {
    categories,
    state: { address, category },
    dispatch: dispatchEventCtx,
  } = useContext(EventContext)

  const noValidate = useNoValidate()
  const submit = () => {
    navigate(computeNextPageWithBaseUri('demande', 'adresse'), {
      state: { next: true },
    })
  }
  const [submitWithDelay, isDelayed] = useWithDelay(submit, 400)

  const { formal, eventAddressProps } = useFormalWithProps(
    {
      eventAddress: (address && address.formatted) || '',
    },
    {
      schema,
      onSubmit: submitWithDelay,
    },
  )

  const { user } = useContext(AuthenticationContext)

  const [profileAddresses, setProfileAddresses] = useState([])

  useEffect(() => {
    if (user) {
      fetchLastUsedAddresses().then(addresses => {
        setProfileAddresses(addresses)
      })
    }
  }, [user])

  const selectAddress = address => {
    formal.change('eventAddress', address && address.formatted)
    dispatchEventCtx({
      type: 'UPDATE',
      payload: { address, zipcode: parseInt(address.zip) },
    })
    submitWithDelay()
  }

  return (
    <StyledAddressPage>
      <DemandeLayout>
        {redirect ? null : (
          <div>
            <SalesPictureTitle
              title={`Bonjour\u00A0!`}
              subTitle={
                <>
                  {`Je vais vous aider à obtenir un devis.`}
                  <br />
                  {`Pourriez-vous me dire où se déroule votre ${(category &&
                    categories
                      .filter(c => c.slug === category)[0]
                      ?.label.toLowerCase()
                      .replace(/\s/, `\u00A0`)) ||
                    `événement`}\u00A0?`}
                </>
              }
            />

            <form
              noValidate={noValidate}
              onSubmit={async e => {
                e.preventDefault()
                try {
                  await formal.validate()
                  await formal.submit()
                } catch (err) {
                  console.info(err)
                }
              }}
            >
              <div className="formats">
                {profileAddresses.map((address, index) => (
                  <Button
                    key={index}
                    className="format"
                    onClick={() => selectAddress(address)}
                  >
                    <div className="address">{address.formatted}</div>
                  </Button>
                ))}
              </div>
              {profileAddresses && profileAddresses.length > 0 && (
                <div className="label">OU CHOISIR UNE AUTRE ADRESSE</div>
              )}
              <AddressInput
                label="Adresse ou ville de l'événement"
                apiKey={process.env.GATSBY_GOOGLE_MAPS_KEY}
                {...eventAddressProps}
                required
                mapOptions={{
                  componentRestrictions: { country: 'fr' },
                }}
                onChange={v => {
                  formal.change('eventAddress', v)
                  dispatchEventCtx({
                    type: 'UPDATE',
                    payload: { address: { formatted: v }, zipcode: null },
                  })
                }}
                secondary
                autoFocus
                onAddressSelected={v => {
                  formal.change('eventAddress', v && v.formatted)
                  dispatchEventCtx({
                    type: 'UPDATE',
                    payload: { address: v, zipcode: parseInt(v.zip) },
                  })
                  submitWithDelay()
                }}
                autoComplete="off"
                autoCorrect="off"
                autoCapitalize="none"
                spellCheck="false"
              />

              <div className="btn-bar">
                <Button
                  id="previous"
                  className="previous"
                  onClick={() => navigate(-1)}
                >
                  <div>&rarr;</div>
                  <span>&nbsp;Précédent</span>
                </Button>
                <Button id="next" type="submit">
                  {isDelayed ? <Pulse /> : <span>Suivant &rarr;</span>}
                </Button>
              </div>
            </form>
          </div>
        )}
      </DemandeLayout>
    </StyledAddressPage>
  )
}

function fetchLastUsedAddresses() {
  return httpFetch(
    `${process.env.GATSBY_VENUS_BACK_URL}/api/account/addresses?limit=2`,
  )
}

const StyledAddressPage = styled.div`
  @media (max-width: ${breakpoints.mobile}px) {
    .page-content {
      margin-top: ${layout.navbarHeight.mobile}px;
      margin-bottom: 50px;
    }
  }

  ${StyledAddressInput} {
    margin-top: 40px;
  }

  .label {
    margin-top: 40px;
    color: ${colors.text.light};
  }

  .formats {
    display: flex;
    flex-direction: column;

    ${StyledButton} {
      color: inherit;
      text-transform: none;
      letter-spacing: unset;
      text-align: left;
      height: auto;
    }

    ${StyledButton}.format {
      position: relative;
      display: flex;
      align-items: center;

      padding: 16px 20px;
      border: solid 1px ${borders.color.pg};
      box-shadow: 0 4px 6px 0 rgba(126, 126, 126, 0.11);
      background-color: #fff;

      &:first-child {
        margin-top: 20px;
      }

      &:not(:last-child) {
        margin-bottom: 10px;
      }

      &:hover {
        background-color: ${colors.background.semiLight};
      }

      & > :not(:last-child) {
        margin-right: 10px;
      }

      .address {
        font-weight: 500;
        font-size: 1rem;
      }
    }
  }
`

export default AddressPage
export { StyledAddressPage }
